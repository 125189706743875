<template>
  <div class="item">
    <div class="position">
      <div class="name" @click="goDetail(item)">
        {{ item.name | textFormat }}
      </div>
      <div class="money">
        <span v-if="item.salary_lowest != 0"
          >{{ item.salary_lowest | textFormat }}K-{{
            item.salary_highest | textFormat
          }}K</span
        >
        <span v-else>{{ $t("salaryNegotiable") }}</span>
      </div>
    </div>
    <div class="address">
      <div class="education">
        <!--        {{item.address|textFormat}} | {{item.recruit_type}} | {{item.level_require}}-->
        <span>
          {{ item.city_name_en | priorFormat(item.city_name_zh, LOCALE) }}
        </span>
        <span style="margin: 0 1px">|</span>
        <span>
          {{ item.experience | experienceFormat(LOCALE) | textFormat }}
        </span>
        <span style="margin: 0 1px">|</span>
        <span>
          {{ item.level_require | levelRequireFormat(LOCALE) | textFormat }}
        </span>
      </div>
      <div class="time" v-if="LOCALE == 'zh'">
        {{ $t("releasedIn") }} {{ item.created_time | secondFormat("L")
        }}{{ $t("dayss") }}
      </div>
    </div>
    <div class="time1" v-if="LOCALE == 'en'">
      {{ $t("releasedIn") }} {{ item.created_time | secondFormat("L")
      }}{{ $t("dayss") }}
    </div>
    <!-- <div class="btn-wrap">
      <div class="delivery">
        <submitResume
            :position_id="item.position_id"
            @successChange="successSubmitResume"></submitResume>
      </div>
      <div class="reservation">
        <orderTalk
            :in_companyId="item.company_id"
            :in_userId="item.person_in_charge"
            :position_id="item.position_id"
            @successChange="successChange"
        ></orderTalk>
      </div>
    </div> -->
  </div>
</template>

<script>
import submitResume from "~hns/components/button/submitResume";
import orderTalk from "~hns/components/button/orderTalk";
export default {
  name: "viewPostItem",
  components: {
    submitResume,
    orderTalk,
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  mounted() {},
  methods: {
    successSubmitResume() {
      this.$message({
        message: this.$t("successDelivering"),
        type: "success",
        offset: 100,
      });
    },
    successChange() {
      this.$message({
        message: this.$t("orderSuccess"),
        type: "success",
        offset: 100,
      });
    },
    goDetail(item) {
      console.log(item);
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id: item.position_id,
          }),
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.item {
  width: 100%;
  background: #f8f9fc;
  padding: 12px 16px;
  margin-bottom: 12px;

  .position {
    display: flex;
    justify-content: space-between;
    div {
      max-width: 50%;
    }
    .name {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
    }

    .money {
      font-size: 14px;
      font-weight: 400;
      color: #ff2b0d;
    }
  }

  .address {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-top: 8px;
  }
  .time1 {
    font-size: 12px;
    color: #666666;
    margin-top: 8px;
  }
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .delivery {
      margin-right: 16px;
    }
  }
}
</style>
